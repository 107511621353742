export default {
  siteTitle: 'Get bonus',
  blogerName: 'BARASH',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/barashstream',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCwpQ-H2uUJ-ucClsqlL71UQ',
    },
    {
      name: 'vk',
      url: 'https://vk.com/barash10k',
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c36e891c6',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c4b56851e',
      gameTitle: 'Burning Chilli X (BGaming)',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs.com/cb8e6b737',
      gameTitle: 'Royal High-Road (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs.com/c904287ba',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://iz-ir23.com/cc4c51842',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/c4b359e50',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-izpihgrzed.com/ccd58e8e7',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c82d9763f',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-irrs01.com/c06748868',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/cebe4207b',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/c2202bf79',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>BARASH</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'BARASH',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  counter: true,
  bonus100: 'Bônus 100%',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
